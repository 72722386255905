import { Link, useLocation } from 'react-router-dom';
import { LNBPropsType } from '../../../../types/LNBPropsType';
import { useEffect, useState } from 'react';
import tabStore from '../../../../stores/tabStore';

const productPath = '/sellerOffice/manageProduct';

export default function LNBProduct(props: LNBPropsType) {
  const { menuOpen } = props;
  const location = useLocation();

  const [menuActive, setMenuActive] = useState<boolean>(false);

  const handleClickMenu = () => setMenuActive(!menuActive);

  const { items: tabItems, addItem, activeItemByUrl } = tabStore();

  const handleTabList = (e) => {
    const targetPath = e.view.location.pathname;

    const existItemIndex = tabItems.findIndex((item) => item.linkUrl === targetPath);
    if (existItemIndex === -1) {
      addItem({
        linkUrl: e.view.location.pathname,
        name: e.target.innerText,
        active: true,
      });
    }

    activeItemByUrl(e.view.location.pathname);
  };

  useEffect(() => {
    setMenuActive(location.pathname.includes(productPath) ? true : false);
  }, [setMenuActive, location]);

  return (
    <li className={menuActive ? 'open' : ''}>
      <div className="folding-head">
        <div onClick={handleClickMenu} className="folding-btn">
          <i className="ico-manage-product"></i>상품{menuOpen ? '관리' : ''}
        </div>
      </div>
      <div className="folding-panel">
        <ul>
          <li onClick={handleTabList} className="lnb-menu">
            <Link to="manageProduct/basic">기본 상품 관리</Link>
          </li>
          <li onClick={handleTabList} className="lnb-menu">
            <Link to="manageProduct/market">마켓 상품 관리</Link>
          </li>
          <li onClick={handleTabList} className="lnb-menu">
            <Link to={'manageProduct/category'}>카테고리 관리</Link>
          </li>
          <li onClick={handleTabList} className="lnb-menu">
            <Link to={'manageProduct/product'}>상품 관리 설정</Link>
          </li>
        </ul>
      </div>
    </li>
  );
}
